import { svgImages } from "./svgImages";

export const Solutions = [
  {
    title: "solutions.retail.title",
    shortDesc: "solutions.retail.shortDesc",
    url: "solutions.retail.url",
  },
  {
    title: "solutions.hospitality.title",
    shortDesc: "solutions.hospitality.shortDesc",
    url: "solutions.hospitality.url",
  },
  {
    title: "solutions.technologySoftwareHardware.title",
    shortDesc: "solutions.technologySoftwareHardware.shortDesc",
    url: "solutions.technologySoftwareHardware.url",
  },
  {
    title: "solutions.insurance.title",
    shortDesc: "solutions.insurance.shortDesc",
    url: "solutions.insurance.url",
  },
  {
    title: "solutions.professionalServices.title",
    shortDesc: "solutions.professionalServices.shortDesc",
    url: "solutions.professionalServices.url",
  },
  {
    title: "solutions.consumerPackagedGoods.title",
    shortDesc: "solutions.consumerPackagedGoods.shortDesc",
    url: "solutions.consumerPackagedGoods.url",
  },
  {
    title: "solutions.mediaEntertainment.title",
    shortDesc: "solutions.mediaEntertainment.shortDesc",
    url: "solutions.mediaEntertainment.url",
  },
  {
    title: "solutions.developers.title",
    shortDesc: "solutions.developers.shortDesc",
    url: "solutions.developers.url",
  },
  {
    title: "solutions.marketingAgency.title",
    shortDesc: "solutions.marketingAgency.shortDesc",
    url: "solutions.marketingAgency.url",
  },
  {
    title: "solutions.publicFigures.title",
    shortDesc: "solutions.publicFigures.shortDesc",
    url: "solutions.publicFigures.url",
  },
  {
    title: "solutions.restaurant.title",
    shortDesc: "solutions.restaurant.shortDesc",
    url: "solutions.restaurant.url",
  },
  {
    title: "solutions.education.title",
    shortDesc: "solutions.education.shortDesc",
    url: "solutions.education.url",
  },
  {
    title: "solutions.financialServices.title",
    shortDesc: "solutions.financialServices.shortDesc",
    url: "solutions.financialServices.url",
  },
  {
    title: "solutions.healthcare.title",
    shortDesc: "solutions.healthcare.shortDesc",
    url: "solutions.healthcare.url",
  },
];
