import Link from "next/link";
import styles from "./buttonBgAnimate.module.scss";

const ButtonBgAnimate = ({ title, url, outline }) => {
  return (
    <div
      className={`${styles["buttonBgAnimate"]} ${
        outline ? styles["outline"] : ""
      }`}
    >
      <Link href={url} prefetch={false}>{title}</Link>
    </div>
  );
};

export default ButtonBgAnimate;
