import { svgImages } from "./svgImages";

export const Products = {
  productsTabsSection: [
    {
      id: 0,
      title: "productsPage.ourProducts.landingPage.title",
      description: "productsPage.ourProducts.landingPage.description",
      shortDesc: "productsPage.ourProducts.landingPage.shortDesc",
      url: "productsPage.ourProducts.landingPage.url",
      icon: `${svgImages.landingPage}`,
      image: "/images/products/qrhub-landing-page.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/landingPages.json",
    },
    {
      id: 1,
      title: "productsPage.ourProducts.vCardPlus.title",
      description: "productsPage.ourProducts.vCardPlus.description",
      shortDesc: "productsPage.ourProducts.vCardPlus.shortDesc",
      url: "productsPage.ourProducts.vCardPlus.url",
      staticUrl: "productsPage.ourProducts.vCardPlus.staticUrl",
      icon: `${svgImages.vCard}`,
      image: "/images/products/qrhub-vcard-plus.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/vCard.json",
    },
    {
      id: 2,
      title: "productsPage.ourProducts.businessProfile.title",
      description: "productsPage.ourProducts.businessProfile.description",
      shortDesc: "productsPage.ourProducts.businessProfile.shortDesc",
      url: "productsPage.ourProducts.businessProfile.url",
      icon: `${svgImages.businessProfile}`,
      image: "/images/products/qrhub-business-profile.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/businessProfile.json",
    },
    {
      id: 3,
      title: "productsPage.ourProducts.listOfLinks.title",
      description: "productsPage.ourProducts.listOfLinks.description",
      shortDesc: "productsPage.ourProducts.listOfLinks.shortDesc",
      url: "productsPage.ourProducts.listOfLinks.url",
      icon: `${svgImages.listOfLinks}`,
      image: "/images/products/qrhub-list-of-links.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/listOfLinks.json",
    },
    {
      id: 4,
      title: "productsPage.ourProducts.dynamicLink.title",
      description: "productsPage.ourProducts.dynamicLink.description",
      shortDesc: "productsPage.ourProducts.dynamicLink.shortDesc",
      url: "productsPage.ourProducts.dynamicLink.url",
      staticUrl: "/",
      icon: `${svgImages.link}`,
      image: "/images/products/qrhub-link.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/links.json",
    },
    {
      id: 5,
      title: "productsPage.ourProducts.listOfFiles.title",
      description: "productsPage.ourProducts.listOfFiles.description",
      shortDesc: "productsPage.ourProducts.listOfFiles.shortDesc",
      url: "productsPage.ourProducts.listOfFiles.url",
      icon: `${svgImages.listOfFiles}`,
      image: "/images/products/qrhub-documents.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/files.json",
    },
    {
      id: 6,
      title: "productsPage.ourProducts.fileLink.title",
      description: "productsPage.ourProducts.fileLink.description",
      shortDesc: "productsPage.ourProducts.fileLink.shortDesc",
      url: "productsPage.ourProducts.fileLink.url",
      icon: `${svgImages.files}`,
      image: "/images/products/qrhub-image-gallery.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/files.json",
    },
    {
      id: 7,
      title: "productsPage.ourProducts.imageGallery.title",
      description: "productsPage.ourProducts.imageGallery.description",
      shortDesc: "productsPage.ourProducts.imageGallery.shortDesc",
      url: "productsPage.ourProducts.imageGallery.url",
      icon: `${svgImages.gallery}`,
      image: "/images/products/qrhub-image-gallery.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/gallery.json",
    },
    {
      id: 8,
      title: "productsPage.ourProducts.videoPlaylist.title",
      description: "productsPage.ourProducts.videoPlaylist.description",
      shortDesc: "productsPage.ourProducts.videoPlaylist.shortDesc",
      url: "productsPage.ourProducts.videoPlaylist.url",
      icon: `${svgImages.videos}`,
      image: "/images/products/qrhub-video-playlist.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/videos.json",
    },

    {
      id: 9,
      title: "productsPage.ourProducts.appLinks.title",
      description: "productsPage.ourProducts.appLinks.description",
      shortDesc: "productsPage.ourProducts.appLinks.shortDesc",
      url: "productsPage.ourProducts.appLinks.url",
      icon: `${svgImages.appLinks}`,
      image: "/images/products/qrhub-app-links.webp",
      isDynamic: true,
      json: "/images/home/homeProductsSection/appLinks.json",
    },
    {
      id: 10,
      title: "productsPage.ourProducts.directLink.title",
      description: "productsPage.ourProducts.directLink.description",
      shortDesc: "productsPage.ourProducts.directLink.shortDesc",
      url: "productsPage.ourProducts.directLink.url",
      icon: "/images/products/icons/qr-link.svg",
      image: "/images/products/qr-link.webp",
      isDynamic: false,
    },
    {
      id: 11,
      title: "productsPage.ourProducts.vCard.title",
      description: "productsPage.ourProducts.vCard.description",
      shortDesc: "productsPage.ourProducts.vCard.shortDesc",
      url: "productsPage.ourProducts.vCard.url",
      icon: "/images/products/icons/qr-vcard.svg",
      image: "/images/products/qr-vcard-plus.webp",
      isDynamic: false,
      json: "/images/home/homeProductsSection/vCard.json",
    },
    {
      id: 12,
      title: "productsPage.ourProducts.wifi.title",
      description: "productsPage.ourProducts.wifi.description",
      shortDesc: "productsPage.ourProducts.wifi.shortDesc",
      url: "productsPage.ourProducts.wifi.url",
      icon: "/images/products/icons/qr-wi-fi.svg",
      image: "/images/products/qr-wi-fi.webp",
      isDynamic: false,
      json: "/images/home/homeProductsSection/wifi.json",
    },
  ],
  staticVsDynamic: {
    static: {
      title: "productsPage.staticVsDynamic.static.title",
      description: "productsPage.staticVsDynamic.static.description",
      advantages: [
        {
          title: "productsPage.staticVsDynamic.static.advantages.item_0.title",
          desc: "productsPage.staticVsDynamic.static.advantages.item_0.desc",
        },
        {
          title: "productsPage.staticVsDynamic.static.advantages.item_1.title",
          desc: "productsPage.staticVsDynamic.static.advantages.item_1.desc",
        },
        {
          title: "productsPage.staticVsDynamic.static.advantages.item_2.title",
          desc: "productsPage.staticVsDynamic.static.advantages.item_2.desc",
        },
        {
          title: "productsPage.staticVsDynamic.static.advantages.item_3.title",
          desc: "productsPage.staticVsDynamic.static.advantages.item_3.desc",
        },
        {
          title: "productsPage.staticVsDynamic.static.advantages.item_4.title",
          desc: "productsPage.staticVsDynamic.static.advantages.item_4.desc",
        },
        {
          title: "productsPage.staticVsDynamic.static.advantages.item_5.title",
          desc: "productsPage.staticVsDynamic.static.advantages.item_5.desc",
        },
      ],
      features: {
        featureDesc: "productsPage.staticVsDynamic.static.features.featureDesc",
        text: "productsPage.staticVsDynamic.static.features.text",
        lists: [
          {
            desc: "productsPage.staticVsDynamic.static.features.lists.item_0.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.static.features.lists.item_1.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.static.features.lists.item_2.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.static.features.lists.item_3.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.static.features.lists.item_4.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.static.features.lists.item_5.desc",
          },
        ],
      },
    },
    dynamic: {
      title: "productsPage.staticVsDynamic.dynamic.title",
      description: "productsPage.staticVsDynamic.dynamic.description",
      advantages: [
        {
          title: "productsPage.staticVsDynamic.dynamic.advantages.item_0.title",
          desc: "productsPage.staticVsDynamic.dynamic.advantages.item_0.desc",
        },
        {
          title: "productsPage.staticVsDynamic.dynamic.advantages.item_1.title",
          desc: "productsPage.staticVsDynamic.dynamic.advantages.item_1.desc",
        },
        {
          title: "productsPage.staticVsDynamic.dynamic.advantages.item_2.title",
          desc: "productsPage.staticVsDynamic.dynamic.advantages.item_2.desc",
        },
        {
          title: "productsPage.staticVsDynamic.dynamic.advantages.item_3.title",
          desc: "productsPage.staticVsDynamic.dynamic.advantages.item_3.desc",
        },
        {
          title: "productsPage.staticVsDynamic.dynamic.advantages.item_4.title",
          desc: "productsPage.staticVsDynamic.dynamic.advantages.item_4.desc",
        },
        {
          title: "productsPage.staticVsDynamic.dynamic.advantages.item_5.title",
          desc: "productsPage.staticVsDynamic.dynamic.advantages.item_5.desc",
        },
      ],
      features: {
        featureDesc:
          "productsPage.staticVsDynamic.dynamic.features.featureDesc",
        text: "productsPage.staticVsDynamic.dynamic.features.text",
        lists: [
          {
            desc: "productsPage.staticVsDynamic.dynamic.features.lists.item_0.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.dynamic.features.lists.item_1.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.dynamic.features.lists.item_2.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.dynamic.features.lists.item_3.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.dynamic.features.lists.item_4.desc",
          },
          {
            desc: "productsPage.staticVsDynamic.dynamic.features.lists.item_5.desc",
          },
        ],
      },
    },
  },
};
