export const headerItems = {
  mainMenu: {
    items: [
      {
        title: "headerItems.items.item_0.title",
        url: "headerItems.items.item_0.url",
        subItems: [
          {
            title: "headerItems.items.item_0.subItems.item_0.title",
            url: "headerItems.items.item_0.subItems.item_0.url",
          },
          {
            title: "headerItems.items.item_0.subItems.item_1.title",
            url: "headerItems.items.item_0.subItems.item_1.url",
          },
          {
            title: "headerItems.items.item_0.subItems.item_2.title",
            url: "headerItems.items.item_0.subItems.item_2.url",
          },
          {
            title: "headerItems.items.item_0.subItems.item_3.title",
            url: "headerItems.items.item_0.subItems.item_3.url",
          },
          {
            title: "headerItems.items.item_0.subItems.item_4.title",
            url: "headerItems.items.item_0.subItems.item_4.url",
          },
          {
            title: "headerItems.items.item_0.subItems.item_5.title",
            url: "headerItems.items.item_0.subItems.item_5.url",
          },
          {
            title: "headerItems.items.item_0.subItems.item_6.title",
            url: "headerItems.items.item_0.subItems.item_6.url",
          },
          {
            title: "headerItems.items.item_0.subItems.item_7.title",
            url: "headerItems.items.item_0.subItems.item_7.url",
          },
        ],
      },
      // {
      //   title: "headerItems.items.item_1.title",
      //   url: "headerItems.items.item_1.url",
      //   subItems: [
      //     {
      //       title: "headerItems.items.item_1.subItems.item_0.title",
      //       url: "headerItems.items.item_1.subItems.item_0.url",
      //     },
      //     {
      //       title: "headerItems.items.item_1.subItems.item_1.title",
      //       url: "headerItems.items.item_1.subItems.item_1.url",
      //     },
      //     {
      //       title: "headerItems.items.item_1.subItems.item_2.title",
      //       url: "headerItems.items.item_1.subItems.item_2.url",
      //     },
      //     {
      //       title: "headerItems.items.item_1.subItems.item_3.title",
      //       url: "headerItems.items.item_1.subItems.item_3.url",
      //     },
      //     {
      //       title: "headerItems.items.item_1.subItems.item_4.title",
      //       url: "headerItems.items.item_1.subItems.item_4.url",
      //     },
      //     {
      //       title: "headerItems.items.item_1.subItems.item_5.title",
      //       url: "headerItems.items.item_1.subItems.item_5.url",
      //     },
      //     {
      //       title: "headerItems.items.item_1.subItems.item_6.title",
      //       url: "headerItems.items.item_1.subItems.item_6.url",
      //     },
      //     {
      //       title: "headerItems.items.item_1.subItems.item_7.title",
      //       url: "headerItems.items.item_1.subItems.item_7.url",
      //     },
      //   ],
      // },
      {
        title: "headerItems.items.item_2.title",
        url: "headerItems.items.item_2.url",
        subItems: [
          {
            title: "headerItems.items.item_2.subItems.item_0.title",
            url: "headerItems.items.item_2.subItems.item_0.url",
          },
        ],
      },
      {
        title: "headerItems.items.item_3.title",
        url: "headerItems.items.item_3.url",
      },
      {
        title: "headerItems.items.item_4.title",
        url: "headerItems.items.item_4.url",
      },
      {
        title: "headerItems.items.item_5.title",
        url: "headerItems.items.item_5.url",
      },
    ],
  },
};
