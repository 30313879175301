import styles from "./accordionSingle.module.scss";

import SouthEastIcon from "@mui/icons-material/SouthEast";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Link from "next/link";

const AccordionSingle = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    props.setSelectedId(panel);
  };

  useEffect(() => {
    if (props.id == 0) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, []);

  useEffect(() => {
    if (props.selectedId == props.id) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [props.selectedId]);

  return (
    <MuiAccordion
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      onChange={handleChange(props.id)}
      slotProps={{ transition: { timeout: 600 } }}
      className={`${props.Light ? styles["light"] : ""} ${
        styles["MuiAccordion-root-custom"]
      } ${expanded ? styles["expanded"] : ""}  ${
        props.footerList ? styles["footerAccordion"] : ""
      }`}
    >
      <MuiAccordionSummary
        aria-controls={`${props.Title}-content`}
        id={`${props.id}-header`}
        className={`${styles["MuiAccordionSummary-root-custom"]}`}
        expandIcon={
          expanded ? (
            <RemoveIcon className={`${styles["expanededIcon"]}`} />
          ) : (
            <SouthEastIcon className={`${styles["unExpanededIcon"]}`} />
          )
        }
      >
        <div className="Acccontnet">{props.title}</div>
      </MuiAccordionSummary>
      <MuiAccordionDetails
        className={`${styles["MuiAccordionDetails-root-custom"]} `}
      >
        <div className={`${styles["subTitle"]}`}>{props.subTitle}</div>

        {props.footerList ? (
          <>
            <div className={`${styles["footerLinks"]}`}>
              {props.description &&
                props.description.map((itemLink) => {
                  return props.title == "Solutions" ? (
                    <Link
                      href={`/en/solutions/${itemLink.slug}`}
                      key={itemLink.id}
                    >
                      {itemLink?.acf.hero_title}
                    </Link>
                  ) : props.title == "Legal Pages" ? (
                    <Link
                      href={`/en/legal-pages/${itemLink.slug}`}
                      key={itemLink.id}
                    >
                      {itemLink.title.rendered}
                    </Link>
                  ) : props.title == "Products" ? (
                    itemLink?.acf.isdynamic ? (
                      <Link
                        href={`/en/products/${itemLink.slug}`}
                        key={itemLink.id}
                      >
                        {itemLink?.acf.hero_title}
                      </Link>
                    ) : (
                      ""
                    )
                  ) : (
                    <Link href={t(itemLink.url)} key={t(itemLink.title)}>
                      {t(itemLink.title)}
                    </Link>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <div
              className={`${styles["description"]}`}
              dangerouslySetInnerHTML={{ __html: props.description }}
            ></div>
          </>
        )}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};
export default AccordionSingle;
