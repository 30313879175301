import Link from "next/link";
import styles from "./header.module.scss";
import { svgImages } from "@/lib/svgImages";
import { headerItems } from "@/lib/header";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ButtonBgAnimate from "../buttonBgAnimate/buttonBgAnimate";
import { Products } from "@/lib/products";
import { Solutions } from "@/lib/solutions";
import MegaMenu from "../megaMenu/megaMenu";
import RoundedButton from "../roundedButton/roundedButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Image from "next/image";

export default function Header() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [solutionsData, setSolutionsData] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const handleChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false);
    setSelectedId(panel);
  };
  // useEffect(() => {
  //   if (selectedId == id) {
  //     setExpanded(true);
  //   } else {
  //     setExpanded(false);
  //   }
  // }, [selectedId]);

  function handleMenuOpen() {
    setMenuOpen(!menuOpen);
  }

  function handleDataFromChild(data) {
    setIsOpen(data);
  }
  useEffect(() => {
    (async () => {
      var productData = null;

      const data = await fetch(
        "https://cms.qrhub.io/wp-json/wp/v2/solutions?per_page=100"
      ).then(
        (res) => res.json()
        // const data = await fetch("/posts.json").then((res) => res.json()
      );
      setSolutionsData(data);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        productData = element;

        const svgData = await fetch("/images.json").then((res) => res.json());

        setAllImages(svgData);

        break;
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var productData = null;

      const data = await fetch(
        "https://cms.qrhub.io/wp-json/wp/v2/products?per_page=100"
      ).then(
        (res) => res.json()
        // const data = await fetch("/posts.json").then((res) => res.json()
      );
      setProductsData(data);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        productData = element;

        const svgData = await fetch("/images.json").then((res) => res.json());

        setAllImages(svgData);

        break;
      }
    })();
  }, []);

  return (
    <>
      <div
        className={`${styles["overlay"]} ${isOpen ? styles["show"] : ""}`}
      ></div>
      <header className={`${styles["header"]}`}>
        <div className={`container`}>
          <div className="inner">
            {/* Desktop Version */}
            <div className={`${styles["headerContainer"]} d-flex-lg d-none-xs`}>
              <div className={`${styles["left"]}`}>
                <Link href="/en/">
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: svgImages.qrHubLightLogo,
                    }}
                  ></div> */}
                  <Image
                    src={`/images/qrhub-logo.svg`}
                    width={120}
                    height={50}
                    alt=""
                  />
                </Link>
              </div>

              <div
                className={`${styles["center"]}`}
                onMouseLeave={() => {
                  setIsOpen(false);
                }}
              >
                <nav>
                  {headerItems.mainMenu.items &&
                    headerItems.mainMenu.items.map((itemLink, i) => (
                      <>
                        <div className={`${styles["mainMenuItem"]}`}>
                          {headerItems.mainMenu.items[i].subItems ? (
                            <Link
                              className={`${styles["mainMenuItemLink"]}  ${
                                isOpen ? styles["open"] : ""
                              }`}
                              key={t(itemLink.title)}
                              href={t(itemLink.url)}
                              onMouseEnter={() => {
                                setIsOpen(true);
                              }}
                              onClick={() => {
                                setIsOpen(false);
                              }}
                            >
                              {t(itemLink.title)}
                            </Link>
                          ) : (
                            <Link
                              className={`${styles["mainMenuItemLink"]}`}
                              key={t(itemLink.title)}
                              href={t(itemLink.url)}
                              onMouseEnter={() => {
                                setIsOpen(false);
                              }}
                            >
                              {t(itemLink.title)}
                            </Link>
                          )}

                          {headerItems.mainMenu.items[i].subItems ? (
                            <>
                              <div
                                className={`${styles["dropdownList"]} ${
                                  isOpen ? styles["open"] : ""
                                }`}
                              >
                                {t(itemLink.title) == "Products" ? (
                                  <div className="container">
                                    <div className="inner">
                                      {productsData && (
                                        <MegaMenu
                                          menuItems={productsData}
                                          isSolutions={false}
                                          sendDataToParent1={
                                            handleDataFromChild
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                ) : t(itemLink.title) == "Solutions" ? (
                                  <div className="container">
                                    <div className="inner">
                                      {solutionsData && (
                                        <MegaMenu
                                          menuItems={solutionsData}
                                          isSolutions={true}
                                          sendDataToParent1={
                                            handleDataFromChild
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  headerItems.mainMenu.items[i].subItems.map(
                                    (subItemLink) => (
                                      <Link
                                        key={t(subItemLink.title)}
                                        href={t(subItemLink.url)}
                                      >
                                        {t(subItemLink.title)}
                                      </Link>
                                    )
                                  )
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ))}
                </nav>
              </div>

              <div className={`${styles["right"]}`}>
                <RoundedButton title="Get Started" url="/" />
              </div>
            </div>
            {/* Mobile Version */}
            <div className={`${styles["headerContainer"]} d-none-lg d-flex-xs`}>
              <div className={`${styles["left"]}`}>
                <Link href="/en/">
                  <Image
                    src={`/images/qrhub-logo.svg`}
                    width={120}
                    height={50}
                    alt=""
                  />
                </Link>
              </div>
              <div
                className={`${styles["center"]} ${styles["menu"]} ${
                  menuOpen ? styles["menuOpen"] : ""
                }`}
              >
                <nav className={`${styles["menuItems"]}`}>
                  {headerItems.mainMenu.items &&
                    headerItems.mainMenu.items.map((itemLink, i) => (
                      <>
                        <div
                          className={`${styles["mainMenuItem"]} ${styles["mainMenuItem"]}`}
                        >
                          <Link
                            className={`${styles["mainMenuItemLink"]}`}
                            key={t(itemLink.title)}
                            href={t(itemLink.url)}
                            onClick={() => {
                              setMenuOpen(false);
                            }}
                          >
                            {t(itemLink.title)}
                          </Link>
                        </div>
                      </>
                    ))}
                </nav>
              </div>

              <div className={`${styles["right"]}`}>
                {/* <RoundedButton title="Get Started" url="/" /> */}
                <div
                  id="menuToggle"
                  className={`${styles["menuToggle"]} ${
                    menuOpen ? styles["menuOpen"] : ""
                  }`}
                >
                  <label htmlFor="mobileMenu"></label>
                  <input
                    type="checkbox"
                    onClick={handleMenuOpen}
                    name="mobileMenu"
                  />
                  {/* <span></span> */}
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
