import Image from "next/image";
import styles from "./megaMenuCard.module.scss";
import Link from "next/link";
import { useState } from "react";

const MegaMenuCard = ({
  url,
  withUrl,
  icon,
  withIcon,
  title,
  description,
  sendDataToParent,
}) => {
  const handleHide = () => {
    sendDataToParent(false);
  };

  return (
    <>
      {withIcon ? (
        <Link href={url} onClick={handleHide}>
          <div className={`${styles["megaMenuCard"]}`}>
            <div className={`${styles["left"]} `}>
              <Image src={icon} width={40} height={40} alt="" />
            </div>

            <div className={`${styles["right"]}`}>
              <div className={`${styles["title"]}`}>{title}</div>
              <div className={`${styles["description"]}`}>{description}</div>
            </div>
          </div>
        </Link>
      ) : withUrl ? (
        <Link href={url} onClick={handleHide} prefetch={false}>
          <div className={`${styles["megaMenuCard"]} ${styles["solutions"]}`}>
            <div className={`${styles["right"]}`}>
              <div
                className={`${styles["title"]}`}
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>
              <div
                className={`${styles["description"]}`}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          </div>
        </Link>
      ) : (
        <div className={`${styles["megaMenuCard"]} ${styles["staticProduct"]}`}>
          <div className={`${styles["right"]}`}>
            <div className={`${styles["title"]}`}>{title}</div>
            <div className={`${styles["description"]}`}>{description}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default MegaMenuCard;
