import Image from "next/image";
import { footeritems } from "@/lib/footer";
import styles from "@/components/footer/footer.module.scss";
import AccordionSingle from "@/components/accordionSingle/accordionSingle";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { svgImages } from "@/lib/svgImages";

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  const [selectedId, setSelectedId] = useState("-1");
  const { t } = useTranslation();
  const [solutionsData, setSolutionsData] = useState(null);
  const [legalsData, setLegalsData] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [allImages, setAllImages] = useState(null);

  useEffect(() => {
    (async () => {
      var productData = null;

      const data = await fetch(
        "https://cms.qrhub.io/wp-json/wp/v2/solutions?per_page=100"
      ).then(
        (res) => res.json()
        // const data = await fetch("/posts.json").then((res) => res.json()
      );
      setSolutionsData(data);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        productData = element;

        const svgData = await fetch("/images.json").then((res) => res.json());

        setAllImages(svgData);

        break;
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var productData = null;

      const data = await fetch(
        "https://cms.qrhub.io/wp-json/wp/v2/legalPages?per_page=100"
      ).then(
        (res) => res.json()
        // const data = await fetch("/posts.json").then((res) => res.json()
      );
      setLegalsData(data);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        productData = element;

        const svgData = await fetch("/images.json").then((res) => res.json());

        setAllImages(svgData);

        break;
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var productData = null;

      const data = await fetch(
        "https://cms.qrhub.io/wp-json/wp/v2/products?per_page=100"
      ).then(
        (res) => res.json()
        // const data = await fetch("/posts.json").then((res) => res.json()
      );
      setProductsData(data);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        productData = element;

        const svgData = await fetch("/images.json").then((res) => res.json());

        setAllImages(svgData);

        break;
      }
    })();
  }, []);

  return (
    <div className={`${styles["footer"]} `}>
      {/* Footer For Desktop */}
      <div className="container">
        <div className="inner">
          <div
            className={`${styles["footerContainerDesktop"]} d-flex-xl d-none-xs`}
          >
            <div className={`${styles["footerSection"]}`}>
              <div
                className={`${styles["mainLogo"]}`}
                dangerouslySetInnerHTML={{ __html: svgImages.qrHubDarkLogo }}
              ></div>
            </div>

            {footeritems.lists &&
              footeritems.lists.map((item) => (
                <>
                  <div className={`${styles["footerSection"]}`}>
                    <div className={`${styles["title"]}`}>
                      {t(item.maintitle)}
                    </div>
                    {t(item.maintitle) == "Solutions" ? (
                      <div className={`${styles["list"]}`}>
                        {solutionsData &&
                          solutionsData.map((itemLink) => (
                            <Link
                              href={`/en/solutions/${itemLink.slug}`}
                              key={itemLink.id}
                            >
                              {itemLink?.acf.hero_title}
                            </Link>
                          ))}
                      </div>
                    ) : t(item.maintitle) == "Legal Pages" ? (
                      <div className={`${styles["list"]}`}>
                        {legalsData &&
                          legalsData.map((itemLink) => (
                            <Link
                              href={`/en/legal-pages/${itemLink.slug}`}
                              key={itemLink.id}
                            >
                              {itemLink.title.rendered}
                            </Link>
                          ))}
                      </div>
                    ) : t(item.maintitle) == "Products" ? (
                      <div className={`${styles["list"]}`}>
                        {productsData &&
                          productsData
                            .sort((a, b) => (a.id < b.id ? -1 : 1))
                            .map((itemLink) =>
                              itemLink?.acf.isdynamic ? (
                                <Link
                                  href={`/en/products/${itemLink.slug}`}
                                  key={itemLink.id}
                                >
                                  {itemLink?.acf.hero_title}
                                </Link>
                              ) : (
                                ""
                              )
                            )}
                      </div>
                    ) : (
                      <div className={`${styles["list"]}`}>
                        {item.items &&
                          item.items.map((itemLink) => (
                            <Link
                              href={t(itemLink.url)}
                              key={t(itemLink.title)}
                            >
                              {t(itemLink.title)}
                            </Link>
                          ))}
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>
          {/* Footer For small Screen */}
          <div
            className={`${styles["footerContainerMobile"]} d-none-xl d-flex-xs`}
          >
            <div className={`${styles["footerListsAccordion"]}`}>
              {footeritems.lists &&
                footeritems.lists.map((item) => (
                  <>
                    <div className={`${styles["footerSection"]}`}>
                      {t(item.maintitle) == "Solutions" ? (
                        <div className={`${styles["list"]}`}>
                          {solutionsData && (
                            <AccordionSingle
                              key={t(item.maintitle)}
                              id={t(item.id)}
                              title={t(item.maintitle)}
                              description={solutionsData}
                              selectedId={selectedId}
                              setSelectedId={setSelectedId}
                              footerList={true}
                              Light="true"
                            />
                          )}
                        </div>
                      ) : t(item.maintitle) == "Legal Pages" ? (
                        <div className={`${styles["list"]}`}>
                          {legalsData && (
                            <AccordionSingle
                              key={t(item.maintitle)}
                              id={t(item.id)}
                              title={t(item.maintitle)}
                              description={legalsData}
                              selectedId={selectedId}
                              setSelectedId={setSelectedId}
                              footerList={true}
                              Light="true"
                            />
                          )}
                        </div>
                      ) : t(item.maintitle) == "Products" ? (
                        <div className={`${styles["list"]}`}>
                          {productsData && (
                            <AccordionSingle
                              key={t(item.maintitle)}
                              id={t(item.id)}
                              title={t(item.maintitle)}
                              description={productsData.sort((a, b) =>
                                a.id < b.id ? -1 : 1
                              )}
                              selectedId={selectedId}
                              setSelectedId={setSelectedId}
                              footerList={true}
                              Light="true"
                            />
                          )}
                        </div>
                      ) : (
                        <div className={`${styles["list"]}`}>
                          {item.items && (
                            <AccordionSingle
                              key={t(item.maintitle)}
                              id={t(item.id)}
                              title={t(item.maintitle)}
                              description={item.items}
                              selectedId={selectedId}
                              setSelectedId={setSelectedId}
                              footerList={true}
                              Light="true"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </>
                ))}
            </div>
          </div>
          {/* copyRight Footer */}
          <div className={`${styles["copyRightFooter"]}`}>
            <div className={`${styles["logo"]} d-none-xl d-flex-xs`}>
              <div
                dangerouslySetInnerHTML={{ __html: svgImages.qrHubDarkLogo }}
              ></div>
            </div>
            <div className={`${styles["textContainer"]} `}>
              <span>© {year}</span>
              <Link href="/en">
                {/* <div
                  className={`${styles["copyRightLogo"]}`}
                  dangerouslySetInnerHTML={{ __html: svgImages.qrHubDarkLogo }}
                ></div> */}
                QRHUB
              </Link>
              <div className={`${styles["text"]}`}>
                {/* by
                <Link href="https://effective.ae/">
                  <div
                    className={`${styles["effectiveLogo"]}`}
                    dangerouslySetInnerHTML={{
                      __html: svgImages.effectiveLogo,
                    }}
                  ></div>
                </Link> */}
                {t(footeritems.copyright)}
              </div>
            </div>
            <div className={`${styles["socialIcons"]}`}>
              {footeritems.socialicons &&
                footeritems.socialicons.map((item) => (
                  <Link
                    href={t(item.url)}
                    key={t(item.icon)}
                    aria-label={t(item.title)}
                  >
                    <Image src={t(item.icon)} width={24} height={24} alt="" />
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
