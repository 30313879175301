import type { AppProps } from "next/app";
import "@/styles/global.scss";
import { useTranslation } from "react-i18next";
import "@/i18n/config";
import Footer from "@/components/footer/footer";
import Header from "@/components/header/header";


export default function App({ Component, pageProps }: AppProps) {
  const { t } = useTranslation();

  return (
    <>
     
      <div className="full-screen">
        <Header />
        <div className="main">
          <Component {...pageProps} />
        </div>
        <Footer />
      </div>
    </>
  );
}
