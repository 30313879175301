import Link from "next/link";
import styles from "./megaMenu.module.scss";
import MegaMenuCard from "@/components/megaMenuCard/megaMenuCard";
import { svgImages } from "@/lib/svgImages";
import { useTranslation } from "react-i18next";
import ButtonBgAnimate from "../buttonBgAnimate/buttonBgAnimate";
import { useEffect, useState } from "react";

const MegaMenu = ({ menuItems, isSolutions, sendDataToParent1 }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  function handleDataFromChild(data) {
    setIsOpen(data);
    sendDataToParent1(data);
  }

  return (
    <div className={`${styles["megaMenu"]}`}>
      <div className={`${styles["left"]}`}>
        <div className={`${styles["text"]}`}>
          {isSolutions ? (
            <div className={`${styles["title"]}`}>
              QR Code Solutions by Industry
            </div>
          ) : (
            <div className={`${styles["title"]}`}>Dynamic Products</div>
          )}
        </div>
        <div className={`${styles["dynamicProducts"]}`}>
          {menuItems
            .sort((a, b) => (a.id < b.id ? -1 : 1))
            .map((item) =>
              item?.acf.isdynamic ? (
                <MegaMenuCard
                  url={`/en/products/${item.slug}`}
                  withUrl={true}
                  icon={`/images/products/icons/${item.slug}.svg`}
                  withIcon={true}
                  title={item.acf.hero_title}
                  description={item.acf.menu_desc}
                  sendDataToParent={handleDataFromChild}
                />
              ) : isSolutions ? (
                <MegaMenuCard
                  url={`/en/solutions/${item.slug}`}
                  withUrl={true}
                  icon={``}
                  withIcon={false}
                  title={item?.acf.hero_title}
                  description={item?.acf.menu_desc}
                  sendDataToParent={handleDataFromChild}
                />
              ) : (
                ""
              )
            )}
        </div>
        {/* <div className={`${styles["rightDottedSquare"]}`}>
          <div className={`${styles["discoverMore"]}`}>
            <Link
              href="/en/products"
              dangerouslySetInnerHTML={{ __html: svgImages.arrowButtonIcon }}
            ></Link>
            <Link href="/en/products">Discover More</Link>
          </div>
        </div> */}
      </div>
      {isSolutions == false ? (
        <div className={`${styles["right"]}`}>
          <div className={`${styles["rightContainer"]}`}>
            <div className={`${styles["text"]}`}>
              <div className={`${styles["title"]}`}>Static Products</div>
              <div className={`${styles["description"]}`}>
                Static QR codes cannot be tracked, provide analytics, or be
                edited after they are created.
              </div>
            </div>
            <div className={`${styles["staticProducts"]}`}>
              {menuItems.map((item) =>
                item?.acf.isdynamic == false ? (
                  <MegaMenuCard
                    url={``}
                    withUrl={false}
                    icon={``}
                    withIcon={false}
                    title={item.acf.hero_title}
                    description={item.acf.menu_desc}
                  />
                ) : (
                  ""
                )
              )}
            </div>
            <ButtonBgAnimate
              title={`Learn More`}
              url={`/en/static-products/`}
              outline={false}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MegaMenu;
