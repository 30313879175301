export const footeritems = {
  logo: "footerItems.logo",
  lists: [
    {
      id: "footerItems.lists.list_0.id",
      maintitle: "footerItems.lists.list_0.maintitle",
      items: [
        {
          title: "footerItems.lists.list_0.items.item_0.title",
          url: "footerItems.lists.list_0.items.item_0.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_1.title",
          url: "footerItems.lists.list_0.items.item_1.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_2.title",
          url: "footerItems.lists.list_0.items.item_2.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_3.title",
          url: "footerItems.lists.list_0.items.item_3.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_4.title",
          url: "footerItems.lists.list_0.items.item_4.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_5.title",
          url: "footerItems.lists.list_0.items.item_5.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_6.title",
          url: "footerItems.lists.list_0.items.item_6.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_7.title",
          url: "footerItems.lists.list_0.items.item_7.url",
        },
        {
          title: "footerItems.lists.list_0.items.item_8.title",
          url: "footerItems.lists.list_0.items.item_8.url",
        },
      ],
    },
    {
      id: "footerItems.lists.list_1.id",
      maintitle: "footerItems.lists.list_1.maintitle",
      items: [
        {
          title: "footerItems.lists.list_1.items.item_0.title",
          url: "footerItems.lists.list_1.items.item_0.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_1.title",
          url: "footerItems.lists.list_1.items.item_1.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_2.title",
          url: "footerItems.lists.list_1.items.item_2.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_3.title",
          url: "footerItems.lists.list_1.items.item_3.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_4.title",
          url: "footerItems.lists.list_1.items.item_4.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_5.title",
          url: "footerItems.lists.list_1.items.item_5.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_6.title",
          url: "footerItems.lists.list_1.items.item_6.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_7.title",
          url: "footerItems.lists.list_1.items.item_7.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_8.title",
          url: "footerItems.lists.list_1.items.item_8.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_9.title",
          url: "footerItems.lists.list_1.items.item_9.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_10.title",
          url: "footerItems.lists.list_1.items.item_10.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_11.title",
          url: "footerItems.lists.list_1.items.item_11.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_12.title",
          url: "footerItems.lists.list_1.items.item_12.url",
        },
        {
          title: "footerItems.lists.list_1.items.item_13.title",
          url: "footerItems.lists.list_1.items.item_13.url",
        },
      ],
    },
    {
      id: "footerItems.lists.list_2.id",
      maintitle: "footerItems.lists.list_2.maintitle",
      items: [
        {
          title: "footerItems.lists.list_2.items.item_0.title",
          url: "footerItems.lists.list_2.items.item_0.url",
        },
        {
          title: "footerItems.lists.list_2.items.item_1.title",
          url: "footerItems.lists.list_2.items.item_1.url",
        },
        {
          title: "footerItems.lists.list_2.items.item_2.title",
          url: "footerItems.lists.list_2.items.item_2.url",
        },
        {
          title: "footerItems.lists.list_2.items.item_3.title",
          url: "footerItems.lists.list_2.items.item_3.url",
        },
        {
          title: "footerItems.lists.list_2.items.item_4.title",
          url: "footerItems.lists.list_2.items.item_4.url",
        },
        {
          title: "footerItems.lists.list_2.items.item_5.title",
          url: "footerItems.lists.list_2.items.item_5.url",
        },
        {
          title: "footerItems.lists.list_2.items.item_6.title",
          url: "footerItems.lists.list_2.items.item_6.url",
        },
        {
          title: "footerItems.lists.list_2.items.item_7.title",
          url: "footerItems.lists.list_2.items.item_7.url",
        },
      ],
    },
    {
      id: "footerItems.lists.list_3.id",
      maintitle: "footerItems.lists.list_3.maintitle",
      items: [
        {
          title: "footerItems.lists.list_3.items.item_0.title",
          url: "footerItems.lists.list_3.items.item_0.url",
        },
        {
          title: "footerItems.lists.list_3.items.item_1.title",
          url: "footerItems.lists.list_3.items.item_1.url",
        },
        {
          title: "footerItems.lists.list_3.items.item_2.title",
          url: "footerItems.lists.list_3.items.item_2.url",
        },
      ],
    },
  ],

  copyright: "footerItems.copyright",
  socialicons: [
    {
      title:"footerItems.socialIcons.item_0.title",
      icon: "footerItems.socialIcons.item_0.icon",
      url: "footerItems.socialIcons.item_0.url",
    },
    {
      title:"footerItems.socialIcons.item_1.title",
      icon: "footerItems.socialIcons.item_1.icon",
      url: "footerItems.socialIcons.item_1.url",
    },
    {
      title:"footerItems.socialIcons.item_2.title",
      icon: "footerItems.socialIcons.item_2.icon",
      url: "footerItems.socialIcons.item_2.url",
    },
  ],
};
