import { useEffect } from "react";
import styles from "./roundedButton.module.scss";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Link from "next/link";

const RoundedButton = ({ title, url, light }) => {
  // useEffect(() => {
  //   const btn = document.querySelector(`${styles["btn"]}`);
  //   const handleMouseMove = (e) => {
  //     const x = e.clientX - btn.offsetLeft;
  //     const y = e.clientY - btn.offsetTop;

  //     btn.style.setProperty("--x", `${x}px`);
  //     btn.style.setProperty("--y", `${y}px`);
  //   };

  //   document.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  return (
    <div
      className={`${styles["roundedButton"]} ${
        light === true ? styles["light"] : ""
      }`}
    >
      <Link href={url} className={`${styles["btn"]}`} prefetch={false}>
        <span>{title}</span>
        <CallMadeIcon />
      </Link>
    </div>
  );
};

export default RoundedButton;
